import { proxy } from 'valtio'
import { AuthStore } from '../auth/authStore.ts'
import {
  ChatApi,
  SubscriptionPlan,
  CloudPaymentsPaymentData,
} from '../../shared/api/chatApi.ts'
import { ErrorNotifier } from '../../shared/api/httpClient.ts'
import { setLoading } from '../../shared/lib/storeUtils.ts'
import { createStoreContext, useStoreContext } from '../../shared/lib/hooks.ts'
import { AppStore } from '../whatisnew/appStore.ts'
import { isMobileByUserAgent } from '../../shared/lib/utils.ts'
import { urls } from '../../shared/urls.ts'

export type Country = 'ru' | 'kz'

interface CloudPaymentsWidget {
  charge(
    paymentData: CloudPaymentsPaymentData,
    onSuccess: (data: string) => void,
    onFail: (reason: string) => void,
  ): void
}

declare const cp: {
  CloudPayments: new () => CloudPaymentsWidget
}

interface State {
  plan: SubscriptionPlan
  trial: boolean
  loading: boolean
  showForm: boolean
  country: Country
  recurrent: boolean
  showReturnMessage: boolean
}

export class BuyStore {
  state = proxy<State>({
    plan: '3M',
    recurrent: true,
    trial: false,
    showForm: false,
    loading: false,
    country: 'ru',
    showReturnMessage: false,
  })

  setCountry(country: Country) {
    this.state.country = country
  }

  get canBuy() {
    return !this.authStore.state.isAnon && this.authStore.isLogin()
  }
  constructor(
    private authStore: AuthStore,
    private appStore: AppStore,
    private chatApi: ChatApi,
    private errorNotifier: ErrorNotifier,
  ) {
    this.state.showReturnMessage = this.canBuy
  }

  setShowForm(showForm: boolean) {
    this.state.showForm = showForm
  }

  async buyWithEmail(email: string) {
    await this.authStore.registration(email, '')
    await this.buy(this.state.plan, this.state.trial)
    this.state.showForm = false
  }

  setRecurrent(recurrent: boolean) {
    this.state.recurrent = recurrent
  }

  setPlan(plan: SubscriptionPlan) {
    this.state.plan = plan
  }

  async buy(plan: SubscriptionPlan, trial: boolean) {
    this.state.plan = plan
    this.state.trial = trial

    if (this.state.country == 'kz') {
      return
    }

    if (!this.canBuy) {
      this.state.showForm = true
      return
    }
    const result = await setLoading(this.state, () =>
      this.state.trial
        ? this.chatApi.subscribe_trial(plan)
        : this.chatApi.subscribe(plan),
    )

    this.authStore.trackMetrika('paing_link_sent')
    this.authStore.trackMetrika('demo2_payment_link')
    void this.chatApi.patchAccount({
      ui_lang: this.appStore.state.language,
      ui_device: isMobileByUserAgent() ? 'mobile' : 'desktop',
    })
    setTimeout(() => {
      if (result.status == 'success') {
        console.log(result.payment_data)
        Object.keys(result.payment_data).length === 0 &&
          (window.location.href = urls.index)

        const paymentData = result.payment_data
        const widget = new cp.CloudPayments()
        widget.charge(
          paymentData,
          (data) => {
            console.log('Payment successful', data)
            window.location.href = urls.index
          },
          (reason) => {
            this.errorNotifier.onError(reason)
          },
        )
      } else {
        this.errorNotifier.onError(result.message)
      }
    }, 100)
  }
}
export const BuyStoreContext = createStoreContext<BuyStore>()

export function useBuyStore() {
  return useStoreContext(BuyStoreContext)
}
