import { usePromise } from '../shared/lib/hooks.ts'
import { useAppModule } from '../features/appContext.ts'
import { Loadable } from '../shared/ui/loadable.tsx'
import { Card } from '../shared/ui/card/card.tsx'
import { classed } from '@tw-classed/react'
import { MailTo } from '../shared/ui/links/links.tsx'
import { Subscription } from '../shared/api/chatApi.ts'
import { Button, LinkButton } from '../shared/ui/button/button.tsx'
import { formatDate } from '../shared/lib/stringUtils.ts'
import { urls } from '../shared/urls.ts'
import { useState } from 'react'
import { Spinner } from '../shared/ui/spinner.tsx'
import { AppHeader } from '../features/appHeader/appHeader.tsx'

import { FormattedMessage, useIntl } from 'react-intl'
import { useSnapshot } from 'valtio/react'

function info(title: string, value: string | number) {
  return (
    <div className="flex gap-8">
      <span className="font-bold">{title}:</span> <span>{value}</span>
    </div>
  )
}

const Subheader = classed.div('mb-16 text-32 font-bold')

export function ProfilePage() {
  const intl = useIntl()
  const appModule = useAppModule()
  const params = usePromise(() =>
    Promise.all([
      appModule.chatsApi.balance(),
      appModule.chatsApi.getAccount(),
      appModule.chatsApi.stat(),
      appModule.subscriptionService().getActiveSubscription(),
    ]),
  )

  return (
    <div className="flex w-full flex-col">
      <AppHeader />
      <Loadable data={params}>
        {(data) => {
          const subscription = data[3]
          return (
            <div className="mx-auto w-full max-w-screen-xl p-8 sm:p-40">
              <div className="flex flex-col gap-16 sm:flex-row">
                <Card bg="gray">
                  <Subheader>
                    <FormattedMessage id="profile.generalInformation" />
                  </Subheader>
                  <div className="flex flex-col gap-16">
                    {info(
                      intl.formatMessage({ id: 'profile.name' }),
                      data[1].name,
                    )}
                    {info(
                      intl.formatMessage({ id: 'profile.phoneNumber' }),
                      data[1].phone_number,
                    )}
                  </div>
                </Card>
                <Card bg="gray">
                  <Subheader>
                    <FormattedMessage id="profile.learningInformation" />
                  </Subheader>
                  <div className="flex flex-col gap-16">
                    {info(
                      intl.formatMessage({ id: 'profile.yourLanguageLevel' }),
                      data[1].level,
                    )}
                    {info(
                      intl.formatMessage({ id: 'profile.yourGoal' }),
                      data[1].onboarding_info.goal,
                    )}
                    {info(
                      intl.formatMessage({ id: 'profile.yourProfession' }),
                      data[1].onboarding_info.profession,
                    )}
                    {info(
                      intl.formatMessage({ id: 'profile.yourInterests' }),
                      data[1].onboarding_info.interests.join(', '),
                    )}
                    {data[2].active
                      ? info(
                          intl.formatMessage({ id: 'profile.activeLessons' }),
                          data[2].active,
                        )
                      : null}
                    {info(
                      intl.formatMessage({ id: 'profile.newLessons' }),
                      data[2].new,
                    )}
                    {info(
                      intl.formatMessage({ id: 'profile.totalLessons' }),
                      data[2].total,
                    )}
                  </div>
                </Card>
                <div className="flex max-w-400 flex-col gap-16">
                  {subscription && (
                    <SubscriptionInfo subscription={subscription} />
                  )}
                  <Card bg="gray">
                    <Subheader>
                      <FormattedMessage id="profile.support" />
                    </Subheader>
                    <FormattedMessage
                      id="profile.supportDescription"
                      values={{
                        br: <br />,
                        email: <MailTo email="info@edman.ai" />,
                      }}
                    />
                  </Card>
                </div>
              </div>
            </div>
          )
        }}
      </Loadable>
    </div>
  )
}

function SubscriptionInfo(props: { subscription: Subscription }) {
  const intl = useIntl()
  const [subscription, setSubscription] = useState(props.subscription)
  const [loading, setLoading] = useState(false)
  const appModule = useAppModule()
  const { authStore } = appModule
  const authState = useSnapshot(authStore.state)

  async function unsubscribe() {
    setLoading(true)
    try {
      await appModule.chatsApi.unsubscribe(subscription.id)
      setSubscription((x) => ({ ...x, recurrent: false }))
    } finally {
      setLoading(false)
    }
  }

  return (
    <Card bg="gray">
      <Subheader>
        <FormattedMessage id="profile.payment" />
      </Subheader>
      <div className="flex flex-col gap-16">
        {info(
          intl.formatMessage({ id: 'profile.subscriptionDate' }),
          formatDate(subscription.subscription_date),
        )}
        {info(intl.formatMessage({ id: 'profile.price' }), subscription.amount)}
        {info(
          intl.formatMessage({ id: 'profile.period' }),
          intl.formatMessage({ id: 'days' }, { count: subscription.days }),
        )}
        {info(
          intl.formatMessage({ id: 'profile.recurrentPayment' }),
          subscription.recurrent
            ? intl.formatMessage({ id: 'profile.enabled' })
            : intl.formatMessage({ id: 'profile.disabled' }),
        )}
        <Button
          onClick={() => {
            if (
              confirm(
                intl.formatMessage({
                  id: 'profile.disableRecurrentPaymentConfirmation',
                }),
              )
            ) {
              void unsubscribe()
            }
          }}
          disabled={loading || !subscription.recurrent}
          rounded="full"
        >
          {loading ? (
            <Spinner size="medium" className="text-white" />
          ) : (
            <FormattedMessage id="profile.disableRecurrentPayment" />
          )}
        </Button>
        {!authState.isLogin && (
          <LinkButton to={urls.subscriptions} rounded="full">
            <FormattedMessage id="Get subscription" />
          </LinkButton>
        )}
      </div>
    </Card>
  )
}
